// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

// eslint-disable-next-line no-unused-vars
const RouterView = {
  name: 'RouterView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '小程序' },
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'user_manage',
        redirect: '/user/index',
        component: PageView,
        hidden: true,
        meta: { title: '管理', keepAlive: true, icon: 'user' },
        children: [
          {
            path: '/user/index',
            name: 'User',
            component: () => import('@/views/a-user/UserInformation'),
            meta: { title: '用户', keepAlive: false }
          },
          {
            path: '/answer/index',
            name: 'Customer',
            component: () => import('@/views/a-customer/AnswerLogs'),
            meta: { title: '答题记录', keepAlive: false }
          }
        ]
      },
      {
        path: '/index',
        name: 'User',
        redirect: '/user/index',
        component: () => import('@/views/a-user/UserInformation'),
        meta: { title: '用户', keepAlive: false, icon: 'user' }
      },
      {
        path: '/answer/index',
        redirect: '/answer/index',
        name: 'Customer',
        component: () => import('@/views/a-customer/AnswerLogs'),
        meta: { title: '答题记录', keepAlive: false, icon: 'database' }
      },
      {
        path: '/account/settings',
        component: PageView,
        redirect: '/account/settings/base',
        hidden: true,
        name: 'account',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: [ 'user' ] },
        children: [
          {
            path: '/account/settings/base',
            name: 'BaseSettings',
            component: () => import('@/views/account/settings/BaseSetting'),
            meta: { title: '基本设置', hidden: true, permission: [ 'user' ] }
          }
        ]
      }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]
