// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
// import themePluginConfig from '../config/themePluginConfig'
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
// import './permission' // permission control
import './permission'
import './utils/filter' // global filter
import './global.less' // global style
import { Dictionaries } from './common/AllConstant'
// import 'xe-utils'
import CustomImportFileForm from './components/CustomImportFile'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
import VueHtmlToPaper from '@/utils/vue-html-to-paper'
// import carPlateKeyboard2 from './components/car-plate-keyboard'
// import 'car-plate-keyboard/lib/carPlateKeyboard.css'

Vue.use(CustomImportFileForm)
// Vue.use(carPlateKeyboard2)
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}
const debounce = function (fn, wait) {
  var timer = null
  return function () {
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(fn, wait)
  }
}
Vue.use(VueHtmlToPaper, options)

// Vue.use(VXETable)
Vue.prototype.$Dictionaries = Dictionaries
Vue.prototype.$debounce = debounce
Vue.config.productionTip = false
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

// window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
